$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.tag {
  span {
    font-weight: 400;
    line-height: 1.5rem;
    padding-block: 0.25rem;
    border-radius: 3.125rem;
    color: $secondary-900;
  }

  &.success {
    background-color: $success-300;
  }

  &.error {
    background-color: $error-300;
  }

  &.info {
    background-color: $info-300;
  }

  &.warning {
    background-color: $warning-300;
  }

  &.primary {
    background-color: $primary-300;
  }

  &.neutral {
    border: 1px solid $secondary-300;
  }

  &.small {
    padding-block: 0;
    padding-inline: 0.25rem;
    font-size: 0.875rem;
  }

  &.medium {
    padding-inline: 0.5rem;
  }

  &.large {
    padding-inline: 0.75rem;
  }

  &.square {
    border-radius: 0.25rem;
  }
}
