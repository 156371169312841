$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.stepper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 400;
  color: $secondary-400;
  text-wrap: nowrap;
}

.step {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  padding-right: 0.75rem;
  gap: 0.5rem;
  border-radius: 2rem;

  &--completed {
    background-color: $primary-200;
  }

  &--error {
    background-color: $error-200;
  }

  &--closed {
    background-color: $secondary-200;
  }
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  color: $white;
  background-color: $secondary-300;

  &--completed {
    background-color: $primary-500;
  }

  &--error {
    background-color: $error-600;
  }

  &--closed {
    background-color: $secondary-600;
  }
}

.stepName--completed {
  color: $secondary-900;
}

.stepLine {
  width: 1.5rem;
  border: 1px solid $secondary-300;
}
