$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;

  .pageButton {
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 2.375rem;
    justify-content: center;
  }

  .previousNextButton {
    display: flex;
    height: 2.375rem;
  }
}

.pages {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ellipsis {
  margin: 0 10px;
}
