$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

table.table {
  font-weight: 400;
  font-size: 0.875rem;
  border-collapse: separate;
  border-spacing: 0 1rem;

  &.hoverable tbody tr:hover {
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  thead {
    th {
      padding: $table-cell-padding-y $table-cell-padding-x;
      border-bottom: 1px solid $secondary-200;

      &:not(:first-of-type) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 30%;
          left: 0;
          height: 40%;
          width: 1px;
          background-color: $secondary-200;
        }
      }
    }
  }

  tbody {
    tr {
      border-radius: $border-radius-md;
      
      td {
        &:last-of-type {
          border-top-right-radius: $border-radius-md;
          border-bottom-right-radius: $border-radius-md;
        }

        &:first-of-type {
          border-top-left-radius: $border-radius-md;
          border-bottom-left-radius: $border-radius-md;
        }
      }
    }
  }
}
