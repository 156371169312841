$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$dropdownSidePadding: 0.5rem;

.searchDropdown {
  .label {
    color: $secondary-900;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .dropdownWithLabel {
    padding-top: 0.25rem;
  }

  .dropdownContainer {
    position: relative;
    display: flex;
    align-items: center;

    .searchDropdownInput {
      width: 100%;

      &.hasSelectedItems {
        .textField {
          input::placeholder {
            color: $secondary-900;
          }
        }
      }

      &.hasDecoration {
        input {
          padding-left: 0.75rem;
        }
      }

      .textField {
        input {
          padding-right: 1.75rem;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }

    .decorationIcon {
      position: absolute;
      right: 0.625rem;
      cursor: pointer;
      color: $secondary-700;

      &.hasHint,
      &.hasError {
        top: 1.2rem;

        &.medium {
          top: 0.8rem;
        }
      }
    }

    div.dropdownMenu {
      width: 100%;
      font-weight: 400;
      font-size: 0.875rem;
      border-radius: $border-radius-md;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
      overflow: hidden !important;
      width: fit-content;
      min-width: 100%;

      .dropdownContent {
        height: 100%;
        overflow: auto;
        max-height: 19rem;
        padding: 1rem $dropdownSidePadding;

        .noItems {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          .icon {
            width: 2rem;
            height: 2rem;
            color: $secondary-200;
          }

          .description {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            color: $secondary-500;
          }
        }
      }

      .groupLabel {
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $secondary-400;
      }

      .menuItem {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: $secondary-700;
        padding: 0.75rem;
        border-radius: $border-radius-md;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: $secondary-100;
        }

        &:global(.disabled) {
          color: $secondary-500;

          .subtitle {
            color: $secondary-400;
          }
        }

        .subtitle {
          display: block;
          color: $secondary-500;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.dropdownFooter {
  display: flex;
  justify-content: center;
  margin: 0 ($dropdownSidePadding * -1);
  background-color: $secondary-50;
}

.divider {
  display: flex;
  height: 0.0625rem;
  background-color: $secondary-200;
  margin: 1rem 0;
}
