$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.iconButton:is(a, button) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: $secondary-700;
  border: none;
  border-radius: 50%;

  &.active:active,
  &:hover {
    background-color: $secondary-100 !important;
    color: $secondary-700;
  }

  &[disabled] {
    background-color: transparent;

    color: $secondary-500;
  }

  &.small {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.875rem;
  }

  &.medium {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1rem;
  }

  &.large {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.25rem;
  }

  &.x-large {
    width: 3.25rem;
    height: 3.25rem;
    font-size: 1.5rem;
  }
}
.spinner {
  margin-right: 0.5rem;
}
